import {
  DataTable,
  Column,
  Tag,
  PaginatorPageState,
} from '@agro1desenvolvimento/react-components';
import { FC } from 'react';
import { useIsMobile } from '@agro1desenvolvimento/react-hooks';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CampoProducao } from '../../../../@types/sementes/campoProducao';
import { selectCamposProducaoState } from '../camposProducaoSlice';
import DropwDownButton from '../../../../components/DropwDownButtons';
import { AuditoriaType } from '../../../../@types/enums';
import { formatNumber, formatWithoutTime } from '../../../../utilities';
import Filters from './Filters';
import Pagination from '../../../../components/Pagination';
import { usePagination } from '../../../../hooks';

const ListCamposProducao: FC = () => {
  const { campos, pagination } = useSelector(selectCamposProducaoState);
  const {
    maxPerPage = 1,
    totalRecords,
    pages,
  } = pagination;
  const isMobile = useIsMobile();
  const history = useHistory();
  const { firstIndexOnPage, goToPage } = usePagination({ maxPerPage });

  const onPageChange = (e: PaginatorPageState) => goToPage(e.page + 1);
  const renderStatus = (campo: CampoProducao) => {
    const severityStatus = {
      homologado: 'info',
      aprovado: 'success',
      rejeitado: 'danger',
    };
    return (
      <Tag value={campo.status} severity={severityStatus[campo.status]} />
    );
  };

  const renderActionButtons = (campoProducao: CampoProducao) => (
    <div>
      <DropwDownButton
        auditoriaAction={() => history.push(`/auditoria/${AuditoriaType.camposProducao}/${campoProducao.id}`)}
        editAction={() => history.push(`/sementes/campos_producao/editar/${campoProducao.id}`)}
      />
    </div>
  );

  return (
    <>
      <DataTable value={campos} emptyMessage="Nenhum registro encontrado." header={<Filters />} responsiveLayout="scroll">
        {isMobile || <Column field="identificacaoSigef" header="Descrição" sortable />}
        {isMobile || <Column field="dataPlantio" body={(rowData: CampoProducao) => formatWithoutTime(rowData.dataPlantio)} header="Data Plantio" sortField="dataPlantio" sortable />}
        <Column field="especie.descricao" header="Espécie" sortable />
        <Column field="cultivar.descricaoCategoria" header="Cultivar" sortable />
        {isMobile
          || (
            <Column
              body={(rowData: CampoProducao) => formatNumber(rowData.area, 1)}
              header="Área"
              sortField="area"
              sortable
            />
          )}
        {isMobile || <Column field="cooperado" header="Cooperado" />}
        {isMobile || (
          <Column field="periodo.descricao" header="Período" />
        )}

        {isMobile || <Column body={renderStatus} header="Status" />}
        <Column header="Ações" body={renderActionButtons} className="col-actions" />
      </DataTable>
      <Pagination
        pages={pages}
        first={firstIndexOnPage}
        rows={maxPerPage}
        totalRecords={totalRecords}
        onPageChange={(e) => onPageChange(e)}
        pageRecords={campos.length}
      />
    </>

  );
};

export default ListCamposProducao;
