import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { Periodo } from '../../@types/geral/periodo';
import CrudBase from '../crud-base';

class PeriodosService extends CrudBase<Periodo> {
  get endpoint() {
    return `/${loginService.scope}/geral/periodos`;
  }
}

export default new PeriodosService();
