import React, { FC, useMemo, useState } from 'react';
import { Dialog, InputText, Button } from '@agro1desenvolvimento/react-components';
import classNames from 'classnames';
import * as Yup from 'yup';
import axios from 'axios';
import { ptForm } from 'yup-locale-pt';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CampoProducaoService from '../../../../services/sementes/campos-producao';
import catchApiErrorsAndSetFormErrors from '../../../../utilities/catch-api-errors';
import ShowErrorHelper from '../../../../components/ShowErrorHelper';
import { CrudCreateUpdate, promiseWithLoaderAndMessages, transitionOptionsTimeout as timeout } from '../../../../utilities';
import { toastSaveMessages } from '../../../../utilities/default-confirmation-messages';
import { CampoProducao } from '../../../../@types/sementes/campoProducao';
import { fetchCampos } from '../camposProducaoSlice';

const Footer: React.FC<{close: () => void, valid: boolean}> = ({ close, valid }) => (
  <div>
    <Button label="Cancelar" icon="pi pi-times" onClick={close} className="p-button-text" />
    <Button label="Salvar" disabled={!valid} icon="pi pi-check" type="submit" form="form-save-campo-producao" />
  </div>
);

Yup.setLocale(ptForm);
const campoProducaoSchema = Yup.object().shape({
  area: Yup.number().positive().required(),
});

interface CampoProducaoFormField {
  id?: string,
  area?: number,
  descricao?: string,
  especie?: string,
  cultivar?: string,
  dataPlantio?: string,
  cooperado?: string,
  periodo?: string,
}

const Form: FC<{ campoProducao?: CampoProducao }> = ({ campoProducao }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(true);

  const onClose = () => history.push('/sementes/campos_producao');

  const fechaModal = () => {
    setModalVisible(false);
  };

  const form = useFormik<CampoProducaoFormField>({
    validationSchema: campoProducaoSchema,
    initialValues: {
      id: campoProducao?.id,
      area: campoProducao?.area,
      especie: campoProducao?.especie.descricao,
      cultivar: campoProducao?.cultivar.descricaoCategoria,
      descricao: campoProducao?.identificacaoSigef,
      dataPlantio: campoProducao?.dataPlantio,
      cooperado: campoProducao?.cooperado,
      periodo: campoProducao?.periodoId,
    },
    onSubmit: async (values) => {
      const toSave: Partial<CrudCreateUpdate<CampoProducao>> = {
        id: values.id,
        area: values.area,
      };

      try {
        await promiseWithLoaderAndMessages(
          dispatch,
          CampoProducaoService.update(toSave),
          toastSaveMessages('campo produção'),
        );

        fechaModal();
        dispatch(fetchCampos());
      } catch (err) {
        if (axios.isAxiosError(err)) {
          catchApiErrorsAndSetFormErrors(form.setFieldError, err.response?.data, true);
        }
      }
    },
  });

  const classes = useMemo(() => (
    classNames({ 'p-invalid': form.errors.area })
  ), [form.errors]);

  const readOnlyValues = [
    { label: 'Descrição', key: 'descricao', value: form.values.descricao },
    { label: 'Cooperado', key: 'cooperado', value: form.values.cooperado },
    { label: 'Espécie', key: 'especie', value: form.values.especie },
    { label: 'Cultivar', key: 'cultivar', value: form.values.cultivar },
  ];

  return (
    <Dialog
      onHide={fechaModal}
      transitionOptions={{ timeout, onExited: onClose }}
      visible={modalVisible}
      maximizable
      header="Alterar campo produção"
      className="dialog-md"
      footer={<Footer close={fechaModal} valid={form.dirty && form.isValid} />}
    >
      <form onSubmit={form.handleSubmit} id="form-save-campo-producao">
        {
          readOnlyValues.map((item) => (
            <div key={item.key} className="p-fluid">
              <div className="p-field">
                <label htmlFor={item.key}>{item.label}</label>
                <InputText
                  disabled
                  readOnly
                  name={item.key}
                  id={item.key}
                  value={item.value}
                  className={classes}
                />
              </div>
            </div>
          ))
        }
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="area">Área</label>
            <InputText
              required
              type="number"
              name="area"
              id="area"
              value={form.values.area}
              placeholder="Casas decimais"
              onChange={form.handleChange}
              className={classNames({ 'p-invalid': form.errors.area })}
              aria-describedby="area-help"
            />
            <ShowErrorHelper id="area-help" error={form.errors.area} />
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default Form;
