import {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { join, map } from 'lodash';
import { useDispatch } from 'react-redux';
import { Column, DataTable } from '@agro1desenvolvimento/react-components';
import { Link } from 'react-router-dom';
import LocalizacaoLotesService from '../../../../services/sementes/localizacao-lotes';
import LoteService from '../../../../services/sementes/lotes';
import ExpandedRowItemTemplate from '../../../../components/ExpandedRowItemTemplate';
import { falhaAoCarregar } from '../../../../utilities/default-confirmation-messages';
import { CampoProducao } from '../../../../@types/sementes/campoProducao';
import { Lote } from '../../../../@types/sementes/lote';
import { formatNumber, formatWithoutTime, promiseWithLoaderAndMessages } from '../../../../utilities';
import { LoteWithLocalizacao } from '../../../../@types/sementes/reembalagem';
import ListaLotes from '../../Reembalagem/components/ListaLotes';

const formatNameCamposProducao = (campoProducoes: CampoProducao[]) => campoProducoes.map((campoProducao) => campoProducao.identificacaoSigef.trim()).join(', ');

interface LoteComLocalizacao {
  quantidade: string,
  localizacao: string,
  pesoTotalKg: string,
  armazemId: string,
}

const RowExpansionTemplate: FC<{loteId: string, isMobile: boolean}> = ({ loteId, isMobile }) => {
  const dispatch = useDispatch();
  const [loteComLocalizacao, setLoteComLocalizacao] = useState<LoteWithLocalizacao[]>([]);
  const [localizacaoLoaded, setLocalizacaoLoaded] = useState(false);
  const [lote, setLote] = useState<Lote>();

  const loteLocalizado = useMemo((): LoteComLocalizacao[] => (
    map(loteComLocalizacao, ({
      armazem, itemCroqui, quantidade, lote: { pesoEmbalagem, pesoTotalKg },
    }) => ({
      armazemId: armazem?.id,
      quantidade: formatNumber(quantidade, 1) || '',
      localizacao: armazem ? join([armazem.descricao, itemCroqui.descricao], ' - ') : '',
      pesoTotalKg: formatNumber(pesoEmbalagem ? quantidade * pesoEmbalagem : pesoTotalKg, 1) || '',
    }))
  ), [loteComLocalizacao]);

  useEffect(() => {
    const loadLote = async () => {
      setLote(await promiseWithLoaderAndMessages(
        dispatch,
        LoteService.find(loteId),
        { errorMessage: falhaAoCarregar('dados do lote') },
      ));
    };

    if (loteId) loadLote();
  }, [loteId]);

  useEffect(() => {
    if (!lote) return;

    const loadLocalizacao = async () => {
      setLoteComLocalizacao(await promiseWithLoaderAndMessages(
        dispatch,
        LocalizacaoLotesService.localizacao([lote]),
        { errorMessage: falhaAoCarregar('localização do lote') },
      ));
      setLocalizacaoLoaded(true);
    };

    if (lote.status !== 'rascunho') loadLocalizacao();
  }, [lote]);

  const renderReembalagensLink = () => {
    const reembalagens = lote?.reembalagemDestino || [];

    return (
      <p className="row-item"><b>Nº da reembalagem:</b> {
          reembalagens.length ? map(reembalagens, (reembalagem) => (
            <Link to={`/sementes/reembalagens/${reembalagem.id}/confirmar`}>{reembalagem.numeroReembalagem} </Link>
          )) : 'N/D'
        }
      </p>
    );
  };

  if (!lote) return null;
  return (
    <>
      <div className="p-p-2 row-expanded">
        <ExpandedRowItemTemplate label="ID" value={lote.id} />
        <ExpandedRowItemTemplate label="Peneira" value={lote.peneira?.descricao} />
        <ExpandedRowItemTemplate label="PMS" value={formatNumber(lote.pms, 1)} />
        <ExpandedRowItemTemplate label="Produtor" value={lote.produtor.nome} />
        <ExpandedRowItemTemplate label="UBS" value={lote.ubs.descricao} />
        <ExpandedRowItemTemplate label="Período" value={lote.periodo?.descricao} />
        <ExpandedRowItemTemplate label="Data validade" value={formatWithoutTime(lote.dataValidade)} />
        <ExpandedRowItemTemplate
          label={lote.campoProducoes.length > 1 ? 'Campos de Produção' : 'Campo de Produção'}
          value={formatNameCamposProducao(lote.campoProducoes)}
        />
        <ExpandedRowItemTemplate label="Data" value={formatWithoutTime(lote.dataLote)} hidden={!isMobile} />
        <ExpandedRowItemTemplate
          label="Nº do planejamento"
          value={lote.planejamento?.numeroPlanejamento}
          link={`/sementes/planejamentos/${lote.planejamento?.id}/lotes`}
        />
        { renderReembalagensLink() }
        <ExpandedRowItemTemplate label="Especie" value={lote.cultivar.especie.descricao} hidden={!isMobile} />
        <ExpandedRowItemTemplate label="Unidade" value={lote.unidade.descricao} hidden={!isMobile} />
        <ExpandedRowItemTemplate label="Quantidade" value={formatNumber(lote.quantidade, 1)} hidden={!isMobile} />
        <ExpandedRowItemTemplate label="Peso Embalagem (kg)" value={formatNumber(lote.pesoEmbalagem, 1)} hidden={!isMobile} />
        <ExpandedRowItemTemplate label="Peso Total (kg)" value={formatNumber(lote.pesoTotalKg, 1)} hidden={!isMobile} />
      </div>

      <div className="p-pl-2 p-py-1 p-ml-2" hidden={!lote.reembalagemDestino?.length}>
        <b>Este lote possui reembalagens associadas</b>
      </div>
      <div className="p-pl-2 p-py-1 p-ml-2" hidden={!localizacaoLoaded}>
        <b hidden={!!loteLocalizado?.length}>Lote sem saldo disponível</b>
      </div>

      <div className="p-mt-2 cy-saldo-lote" hidden={!loteLocalizado?.length}>
        <DataTable header={<h4 className="p-mb-0">Saldo</h4>} value={loteLocalizado} className="p-datatable-gridlines" responsiveLayout="scroll">
          <Column
            sortable
            header="Localização"
            className="p-text-center"
            bodyClassName="cy-localizacao"
            body={(local: LoteComLocalizacao) => (
              <Link to={`/sementes/lotes/localizacao/${local.armazemId}/${encodeURIComponent(lote.numeroLote)}`}>
                {local.localizacao}
              </Link>
            )}
          />
          <Column field="quantidade" bodyClassName="cy-quantidade" header="Quantidade" sortable className="p-text-center" />
          <Column field="pesoTotalKg" bodyClassName="cy-peso-total" header="Peso Total (kg)" sortable className="p-text-center" />
        </DataTable>
      </div>
      <div className="p-mt-2" hidden={!lote?.lotesOrigem?.length}>
        <ListaLotes lotes={lote.lotesOrigem!} header={<h4 className="p-m-0">Lotes de origem</h4>} footer />
      </div>
      <div className="p-mt-2" hidden={!lote?.lotesDestino?.length}>
        <ListaLotes lotes={lote.lotesDestino!} header={<h4 className="p-m-0">Lotes de destino</h4>} footer />
      </div>
    </>
  );
};

export default RowExpansionTemplate;
